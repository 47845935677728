import React, { memo, useState, useEffect, useRef } from "react";
import {
  capitalize,
  capitalizeAll,
  filterFromdateName,
  filterTodateName,
  useOutsideAlerter,
} from "../../utils/helper";
import filter from "../../assets/images/filter.svg";
import filterDateLogo from "../../assets/admin Images/filter-date-logo.svg";
import SearchSelect from "./SearchSelect";
import Select from "react-select";
import { interviewSearchDropdownOptions } from "../../utils/constant";
import { useDispatch, useSelector } from "react-redux";
import {
  getActiveEmployers,
  getActiveIncbotEmployers,
} from "../../redux/action";
import Button from "./Button";
import moment from "moment";

const FilterBtn = memo(
  ({
    filterType,
    setIsFilterData,
    setDataControl,
    setClearFilter,
    type,
    submitFilter,
    refresh,
    isFilterData,
    setRefresh,
    isCompleted,
    subusersList,
    tab,
    filterFor,
    nameText,
    pageName,
  }) => {
    const dispatch = useDispatch();
    const fromDateRef = useRef();
    const toDateRef = useRef();
    const allEmployerList = useSelector(
      (state) => state.UserAuth.activeEmployerList
    );
    const allIncbotEmployerList = useSelector(
      (state) => state.Interview.activeIncbotEmployerList
    );
    const [showFilter, setShowFilter] = useState(false);
    const [employerList, setEmployerList] = useState([]);
    const ref = useRef();
    useOutsideAlerter(ref, () => setShowFilter(false));
    const label = {
      Shortlisted: "shortlisted",
      Scheduled: "scheduled",
      Rejected: "rejected",
      "No Show": "noshow",
      Pending: "pending",
      Cancelled: "canceled",
      "On Hold": "hold",
    };
    const order = {
      "Newest to Oldest": "_id desc",
      "Oldest to Newest": "_id asc",
    };
    if (nameText) {
      order[`${nameText} (A - Z)`] =
        filterType === "interview" ? "candidateName asc" : "title asc";
    } else {
      order["Candidate Name (A - Z)"] =
        filterType === "interview" ? "candidateName asc" : "title asc";
    }
    const labelOptions =
      filterFor !== "candidate"
        ? [
            { label: "Active", value: "Active" },
            { label: "Closed", value: "Closed" },
            { label: "Deleted", value: "deleted" },
          ]
        : [
            { label: "Recommended", value: "Recommended" },
            { label: "Not Recommended", value: "Not Recommended" },
            {
              label: "Cautiously Recommended",
              value: "Cautiously Recommended",
            },
            { label: "Invited", value: "Invited" },
            { label: "Reinvited", value: "Reinvited" },
            { label: "In Progress", value: "In Progress" },
            { label: "Unattended", value: "Unattended" },
            { label: "Canceled", value: "Canceled" },
            {
              label: "Feedback Approval Pending",
              value: "feeback_approval_pending",
            },
          ];
    const feedback = {
      "Excellent: 9 to 10": "excellent",
      "Very Good: 7 to 8": "very_good",
      "Good: 6": "good",
      "Average: 5": "average",
      "Poor: 4": "poor",
      "Very Poor: <= 3": "very_poor",
    };
    const CreditType = [
      { label: "Prepaid", value: "prepaid" },
      { label: "Pay Per Use", value: "pay_per_use" },
    ];
    const [range, setRange] = useState("0");
    const [filterStates, setFilterStates] = useState({
      sort: "",
      status: "",
      from: "",
      to: "",
      experience: "",
      feedback: "",
      createdBy: "",
    });
    const [filterStatus, setFilterStatus] = useState([]);
    useEffect(() => {
      if (type === "incBot") {
        dispatch(getActiveIncbotEmployers());
      } else {
        dispatch(getActiveEmployers());
      }
    }, [dispatch, type]);

    useEffect(() => {
      const tmpArray = [
        {
          label: "Select Employer",
          value: "",
        },
      ];
      if (type === "incBot") {
        allIncbotEmployerList &&
          allIncbotEmployerList.length > 0 &&
          allIncbotEmployerList.map((element) => {
            if (element?.employer !== "") {
              tmpArray.push({
                value: element?._id,
                label: capitalizeAll(element?.employer),
              });
            }
          });
      } else {
        allEmployerList &&
          allEmployerList.length > 0 &&
          allEmployerList.map((element) => {
            if (element?.employer !== "") {
              tmpArray.push({
                value: element?._id,
                label: capitalizeAll(element?.employer),
              });
            }
          });
      }

      setEmployerList(tmpArray);
    }, [allEmployerList]);

    useEffect(() => {
      const element = document.getElementById("rs-bullet");
      if (element) {
        document.getElementById("rs-bullet").style.left = `${
          (range * 80) / 30
        }%`;
      }
    }, [range]);

    const handleShowFilter = () => {
      setShowFilter(!showFilter);
      setRefresh(false);
      setClearFilter(false);
    };

    const handleFilterChange = (e, inputType, inputName) => {
      let filterStatesData;
      if (inputName === "range") {
        setRange(e.target.value);
        setFilterStates({ ...filterStates, [inputType]: e.target.value });
        filterStatesData = { ...filterStates, [inputType]: e.target.value };
      }

      if (!e.target.checked && inputName !== "range") {
        const currentStates = {
          ...filterStates,
        };
        if (inputType === "status") {
          currentStates.status = "";
        }
        setFilterStates(currentStates);
        filterStatesData = { ...filterStates };
        if (inputType === "status") {
          filterStatesData.status = "";
        } else {
          setFilterStates({ ...filterStates, [inputType]: e.target.value });
          filterStatesData = { ...filterStates, [inputType]: e.target.value };
        }
      } else {
        setFilterStates({ ...filterStates, [inputType]: e.target.value });
        filterStatesData = { ...filterStates, [inputType]: e.target.value };
      }
      if (inputType === "createdBy") {
      }
      if (setDataControl) {
        setDataControl((prev) => ({
          ...prev,
          interview: { ...prev.interview, currentPage: 1 },
        }));
      }

      if (filterType === "interview") {
        filterStatesData.status = filterStatesData.status
          ? filterStatesData.status
          : "";
        setIsFilterData(filterStatesData);
      } else {
        setIsFilterData(filterStatesData);
      }
    };
    const handleSelectChange = (e, name) => {
      let filterStatesData;
      setFilterStates({ ...filterStates, [name]: e.value });
      filterStatesData = { ...filterStates, [name]: e.value };
      setIsFilterData(filterStatesData);
    };

    const handleFilterStatusChange = (value) => {
      let filterStatesData;
      const index = filterStatus.indexOf(value);
      let finalStatus;
      if (index === -1) {
        finalStatus = [...filterStatus, value];
        setFilterStatus([...filterStatus, value]);
      } else {
        const newStatus = [...filterStatus];
        newStatus.splice(index, 1);
        finalStatus = newStatus;
        setFilterStatus(newStatus);
      }
      setFilterStates({ ...filterStates, status: finalStatus.join(",") });
      filterStatesData = { ...filterStates, status: finalStatus.join(",") };
      setIsFilterData(filterStatesData);
    };

    const isAnyFieldNotEmpty = () => {
      for (const key in filterStates) {
        if (filterStates[key] && filterStates[key].trim() !== "") {
          return true;
        }
      }
      return false;
    };

    const handleClearFilter = () => {
      let filterStatesData = {
        sort: "",
        status: "",
        from: "",
        to: "",
        experience: "",
        feedback: "",
        createdBy: "",
      };
      if (isAnyFieldNotEmpty()) {
        setShowFilter(false);
        setClearFilter(true);
        setFilterStatus("");
        setFilterStates(filterStatesData);
        setRange("0");
        setIsFilterData(filterStatesData);
      }
    };

    useEffect(() => {
      if (refresh === true) {
        handleClearFilter();
      }
      handleClearFilter();
    }, [refresh, tab]);

    useEffect(() => {
      if (filterType === "interview") {
        setFilterStates({
          sort: "",
          status: "",
          from: "",
          to: "",
          experience: "",
          feedback: "",
          createdBy: "",
        });
      }
      if (type === "incExit") {
        setFilterStates({
          sort: "",
          from: "",
          to: "",
          // experience: "",
        });
      }
      if (type === "incServe") {
        setFilterStates({
          sort: "",
          from: "",
          to: "",
          experience: "",
          user: "",
          updatedFrom: "",
          updatedTo: "",
          acceptedFrom: "",
          acceptedTo: "",
          allotedForm: "",
          allotedTo: "",
          rescheduledFrom: "",
          rescheduledTo: "",
          scheduledFrom: "",
          scheduledTo: "",
          submittedFrom: "",
          submittedTo: "",
          canceledFrom: "",
          canceledTo: "",
        });
      }
      if (type === "incBot") {
        setFilterStates({
          sort: "",
          from: "",
          to: "",
          user: "",
          status: "",
        });
      }
    }, [filterType, type]);

    const minDate = filterStates.from || "";
    const maxDate = new Date().toISOString().split("T")[0];

    return (
      <div className={`filter-btn-container position-relative ms-2`} ref={ref}>
        <button className="filter-btn d-center" onClick={handleShowFilter}>
          <img src={filter} alt="filter-btn" className="filter-img img-fluid" />
        </button>
        {showFilter && (
          <div
            className={`position-absolute filter-btn-popup input-shadow rounded f-reg ${type}`}
          >
            <div className="date-container f-reg">
              <label htmlFor="" className={"f-l"}>
                <span className="f-sem-bol no-wrap">
                  {filterFromdateName(pageName, tab)}
                </span>
                <div
                  className="filter-date-container input-shadow"
                  onClick={() => {
                    if (fromDateRef.current) {
                      fromDateRef.current.showPicker();
                    }
                  }}
                >
                  <input
                    ref={fromDateRef}
                    type="date"
                    max={filterStates?.[filterTodateName(pageName, tab)] ?? ""}
                    value={
                      filterStates?.[filterFromdateName(pageName, tab)] ?? ""
                    }
                    className="filter-input"
                    onChange={(e) =>
                      handleFilterChange(e, filterFromdateName(pageName, tab))
                    }
                  />
                  <div className="filter-date-placeholder f-med">
                    {filterStates?.[filterFromdateName(pageName, tab)]
                      ? moment(
                          filterStates?.[filterFromdateName(pageName, tab)]
                        ).format("DD-MM-YYYY")
                      : "Select a date"}
                  </div>
                  <img
                    src={filterDateLogo}
                    alt="filter date logo"
                    className="filter-date-logo"
                  />
                </div>
              </label>
              <label htmlFor="" className={"f-l"}>
                <span className="f-sem-bol no-wrap">
                  {filterTodateName(pageName, tab)}
                </span>
                <div
                  className="filter-date-container input-shadow"
                  onClick={() => {
                    if (toDateRef.current) {
                      toDateRef.current.showPicker();
                    }
                  }}
                >
                  <input
                    ref={toDateRef}
                    type="date"
                    min={
                      filterStates?.[filterFromdateName(pageName, tab)] ?? ""
                    }
                    value={
                      filterStates?.[filterTodateName(pageName, tab)] ?? ""
                    }
                    className="filter-input"
                    onChange={(e) =>
                      handleFilterChange(e, filterTodateName(pageName, tab))
                    }
                  />
                  <div className="filter-date-placeholder f-med">
                    {filterStates?.[filterTodateName(pageName, tab)]
                      ? moment(
                          filterStates?.[filterTodateName(pageName, tab)]
                        ).format("DD-MM-YYYY")
                      : "Select a date"}
                  </div>
                  <img
                    src={filterDateLogo}
                    alt="filter date logo"
                    className="filter-date-logo"
                  />
                </div>
              </label>
            </div>
            {type !== "incExit" && type !== "incServe" && type !== "incBot" && (
              <>
                <div className="f-med title f-l">Labels</div>
                {Object.entries(label).map((data, index) => (
                  <label
                    htmlFor={data[1]}
                    key={index}
                    className={"f-l d-flex align-items-center"}
                  >
                    <input
                      type="checkbox"
                      value={data[1]}
                      checked={filterStates?.status === data[1]}
                      onChange={(e) => handleFilterChange(e, "status")}
                    />
                    <span>{data[0]}</span>
                  </label>
                ))}
              </>
            )}
            {isFilterData?.status !== "bulk" &&
              type !== "incExit" &&
              type !== "incBot" && (
                <div className="range-box">
                  <div className="f-med title f-l">Experience</div>
                  <div className="range-slider f-l">
                    <div
                      // id="rs-bullet"
                      className="rs-label f-sem-bol d-center"
                      style={{ left: `${(range / 40) * 90}%` }}
                    >
                      {range}
                    </div>
                    <input
                      // id="rs-range-line"
                      className="rs-range"
                      type="range"
                      min={0}
                      max={40}
                      value={range}
                      onChange={(e) =>
                        handleFilterChange(e, "experience", "range")
                      }
                    />
                  </div>
                  <div className="box-minmax f-ph f-sem-bol">
                    <span>0yr</span>
                    <span>40yrs</span>
                  </div>
                </div>
              )}
            {type !== "incExit" && (
              <>
                <SearchSelect
                  name="user"
                  value={
                    filterStates?.user &&
                    employerList &&
                    employerList.find(
                      (itm) => itm?.value === filterStates?.user
                    )
                  }
                  label="Employer"
                  onChange={(e) => handleSelectChange(e, "user")}
                  options={employerList}
                  labelClass="mb-1 mt-2 f-med title f-l"
                  inputClass="f-12 f-sem-bol input-shadow rounded"
                  borderNone
                  placeholder={"Select a Employer"}
                />
              </>
            )}
            {type === "incBot" && (
              <>
                <div className="f-med title f-l">Status</div>
                {labelOptions.map((option, index) => (
                  <label
                    htmlFor={option.value}
                    key={index}
                    className="f-l d-flex align-items-center"
                  >
                    <input
                      type="checkbox"
                      value={option.value}
                      checked={filterStatus.includes(option.value)}
                      onChange={() => handleFilterStatusChange(option.value)}
                    />
                    <span>{option.label}</span>
                  </label>
                ))}
              </>
            )}
            <div className="f-med title f-l">Order By</div>
            {Object.entries(order).map((data, index) => (
              <label
                htmlFor={data[1]}
                key={index}
                className={"f-l d-flex align-items-center"}
              >
                <input
                  type="radio"
                  value={data[1]}
                  checked={filterStates?.sort === data[1]}
                  onChange={(e) => handleFilterChange(e, "sort")}
                />
                <span>{data[0]}</span>
              </label>
            ))}
            {type !== "incExit" && type !== "incServe" && type !== "incBot" && (
              <>
                <div className="f-med title f-l">Feedback Rating</div>
                {Object.entries(feedback).map((data, index) => (
                  <label
                    htmlFor={data[1]}
                    key={index}
                    className={"f-l d-flex align-items-center"}
                  >
                    <input
                      type="checkbox"
                      value={data[1]}
                      checked={filterStates?.feedback === data[1]}
                      onChange={(e) => handleFilterChange(e, "feedback")}
                    />
                    <span>{data[0]}</span>
                  </label>
                ))}
              </>
            )}
            {type === "incServe" && isCompleted && (
              <>
                {/* <SearchSelect
                  name="user"
                  value={filterStates?.user}
                  label="Employer"
                  onChange={(e) => handleSelectChange(e, "user")}
                  options={employerList}
                  labelClass="mb-1 mt-2 f-med title f-l"
                  inputClass="f-12 f-sem-bol input-shadow rounded"
                  placeholder={"Select employer"}
                /> */}
                <SearchSelect
                  name="approvedBy"
                  value={filterStates.approvedBy}
                  label="Approved  By"
                  onChange={(e) => handleSelectChange(e, "approvedBy")}
                  options={subusersList}
                  labelClass="mb-1 mt-2 f-med title f-l"
                  inputClass="f-12 f-sem-bol input-shadow rounded"
                  borderNone
                  placeholder={"Select a subuser"}
                />
                <SearchSelect
                  name="creditType"
                  value={filterStates.creditType}
                  label="Credit Type"
                  onChange={(e) => handleSelectChange(e, "creditType")}
                  options={CreditType}
                  labelClass="mb-1 mt-2 f-med title f-l"
                  inputClass="f-12 f-sem-bol input-shadow rounded"
                  borderNone
                />
                <SearchSelect
                  name="mode"
                  value={filterStates?.mode}
                  label="Interview Mode"
                  onChange={(e) => handleSelectChange(e, "mode")}
                  options={interviewSearchDropdownOptions.mode}
                  labelClass="mb-1 mt-2 f-med title f-l"
                  inputClass="f-l f-reg rounded input-shadow"
                  placeholder={"Select mode"}
                />
              </>
            )}
            {/* <Button
              isImage={false}
              text={"Apply"}
              className="jd-resume-btn w-100 f-10 cursor-pointer rounded"
              onClick={submitFilter}
            /> */}
            {/* <button onClick={submitFilter}>Apply</button> */}
            <button
              className={`filter-clear-btn d-flex align-items-center justify-content-center ${
                isAnyFieldNotEmpty() ? "" : "disabled"
              }`}
              onClick={handleClearFilter}
            >
              Clear Filter
            </button>
          </div>
        )}
      </div>
    );
  }
);

export default FilterBtn;
