import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILURE,
  LOGIN_WITH_GOOGLE,
  LOGIN_WITH_GOOGLE_SUCCESS,
  LOGIN_WITH_GOOGLE_FAILURE,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  LOGOUT_USER_FAILURE,
  SIGNUP_USER,
  SIGNUP_USER_SUCCESS,
  SIGNUP_USER_FAILURE,
  MANUALLY_VERIFY_EMAIL,
  MANUALLY_VERIFY_EMAIL_SUCCESS,
  MANUALLY_VERIFY_EMAIL_FAILURE,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  FORGOT_PASSWORD_TOKEN_VERIFY,
  FORGOT_PASSWORD_TOKEN_VERIFY_SUCCESS,
  FORGOT_PASSWORD_TOKEN_VERIFY_FAILURE,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  GET_EMPLOYER_LIST,
  GET_EMPLOYER_LIST_SUCCESS,
  GET_EMPLOYER_LIST_FAILURE,
  GET_EMPLOYER_ROLE_LIST,
  GET_EMPLOYER_ROLE_LIST_SUCCESS,
  GET_EMPLOYER_ROLE_LIST_FAILURE,
  SEND_EMAIL_VERIFICATION,
  SEND_EMAIL_VERIFICATION_SUCCESS,
  SEND_EMAIL_VERIFICATION_FAILURE,
  DELETE_EMPLOYER_PROFILE,
  DELETE_EMPLOYER_PROFILE_SUCCESS,
  DELETE_EMPLOYER_PROFILE_FAILURE,
  DELETE_EMPLOYER,
  DELETE_EMPLOYER_SUCCESS,
  DELETE_EMPLOYER_FAILURE,
  RESTORE_PROFILE,
  RESTORE_PROFILE_SUCCESS,
  RESTORE_PROFILE_FAILURE,
  EMPLOYER_SUBSCRIPTION,
  EMPLOYER_SUBSCRIPTION_SUCCESS,
  EMPLOYER_SUBSCRIPTION_FAILURE,
  GET_CREDIT_HISTORY,
  GET_CREDIT_HISTORY_SUCCESS,
  GET_CREDIT_HISTORY_FAILURE,
  UPDATE_CREDIT_HISTORY,
  UPDATE_CREDIT_HISTORY_SUCCESS,
  UPDATE_CREDIT_HISTORY_FAILURE,
  UPDATE_SUBSCRIPTION,
  UPDATE_SUBSCRIPTION_SUCCESS,
  UPDATE_SUBSCRIPTION_FAILURE,
  GET_ALL_EMPLOYERS,
  GET_ALL_EMPLOYERS_SUCCESS,
  GET_ALL_EMPLOYERS_FAILURE,
  ADD_OR_EDIT_SUB_USER,
  ADD_OR_EDIT_SUB_USER_SUCCESS,
  ADD_OR_EDIT_SUB_USER_FAILURE,
  GET_SUB_USER,
  GET_SUB_USER_SUCCESS,
  GET_SUB_USER_FAILURE,
  DELETE_SUB_USER,
  DELETE_SUB_USER_SUCCESS,
  DELETE_SUB_USER_FAILURE,
  RESEND_LINK,
  RESEND_LINK_SUCCESS,
  RESEND_LINK_FAILURE,
  ACTIVE_INACTIVE_SUBUSER,
  ACTIVE_INACTIVE_SUBUSER_SUCCESS,
  ACTIVE_INACTIVE_SUBUSER_FAILURE,
  CHANGE_BLOG_STATUS,
  CHANGE_BLOG_STATUS_SUCCESS,
  CHANGE_BLOG_STATUS_FAILURE,
  GET_ROLES,
  GET_ROLES_SUCCESS,
  GET_ROLES_FAILURE,
  ADD_OR_EDIT_ROLE,
  ADD_OR_EDIT_ROLE_SUCCESS,
  ADD_OR_EDIT_ROLE_FAILURE,
  DELETE_ROLES,
  DELETE_ROLES_SUCCESS,
  DELETE_ROLES_FAILURE,
  ACTIVE_INACTIVE_ROLE,
  ACTIVE_INACTIVE_ROLE_SUCCESS,
  ACTIVE_INACTIVE_ROLE_FAILURE,
  GET_PERMISSION,
  GET_PERMISSION_SUCCESS,
  GET_PERMISSION_FAILURE,
  ADD_ROLES,
  ADD_ROLES_SUCCESS,
  ADD_ROLES_FAILURE,
  GET_ROLES_RESOURCES,
  GET_ROLES_RESOURCES_SUCCESS,
  GET_ROLES_RESOURCE_FAILURE,
  GET_ALL_SUB_USER,
  GET_ALL_SUB_USER_SUCCESS,
  GET_ALL_SUB_USER_FAILURE,
  ADD_OR_EDIT_EMPLOYER_SUB_USER,
  ADD_OR_EDIT_EMPLOYER_SUB_USER_SUCCESS,
  ADD_OR_EDIT_EMPLOYER_SUB_USER_FAILURE,
  GET_RESOURCE_ACTIONS,
  GET_RESOURCE_ACTIONS_SUCCESS,
  GET_RESOURCE_ACTIONS_FAILURE,
  GET_CONTACT_INQUIRY,
  GET_CONTACT_INQUIRY_SUCCESS,
  GET_CONTACT_INQUIRY_FAILURE,
  GET_ANNOUNCEMENT_LIST,
  GET_ANNOUNCEMENT_LIST_FAILURE,
  GET_ANNOUNCEMENT_LIST_SUCCESS,
  DELETE_ANNOUNCEMENT,
  DELETE_ANNOUNCEMENT_SUCCESS,
  DELETE_ANNOUNCEMENT_FAILURE,
  GET_USER_INFORMATION,
  GET_USER_INFORMATION_SUCCESS,
  GET_USER_INFORMATION_FAILURE,
  UPDATE_USER_INFORMATION,
  UPDATE_USER_INFORMATION_SUCCESS,
  UPDATE_USER_INFORMATION_FAILURE,
  GET_COMPANY_DETAILS,
  GET_COMPANY_DETAILS_SUCCESS,
  GET_COMPANY_DETAILS_FAILURE,
  UPDATE_COMPANY_DETAILS,
  UPDATE_COMPANY_DETAILS_SUCCESS,
  UPDATE_COMPANY_DETAILS_FAILURE,
  GET_SUPPORT_TICKET,
  GET_SUPPORT_TICKET_SUCCESS,
  GET_SUPPORT_TICKET_FAILURE,
  UPDATE_SUPPORT_TICKET_STATUS,
  UPDATE_SUPPORT_TICKET_STATUS_SUCCESS,
  UPDATE_SUPPORT_TICKET_STATUS_FAILURE,
  DELETE_EMPLOYER_SUB_USER,
  DELETE_EMPLOYER_SUB_USER_SUCCESS,
  DELETE_EMPLOYER_SUB_USER_FAILURE,
  SEND_ANNOUNCEMENT_ITEM,
  SEND_ANNOUNCEMENT_ITEM_SUCCESS,
  SEND_ANNOUNCEMENT_ITEM_FAILURE,
  GET_USER_LIST,
  GET_USER_LIST_SUCCESS,
  GET_USER_LIST_FAILURE,
  EDIT_STATUS_CONTACT_INQUIRY,
  EDIT_STATUS_CONTACT_INQUIRY_SUCCESS,
  EDIT_STATUS_CONTACT_INQUIRY_FAILURE,
  EDIT_USER_CONTACT_INQUIRY,
  EDIT_USER_CONTACT_INQUIRY_SUCCESS,
  EDIT_USER_CONTACT_INQUIRY_FAILURE,
  GET_REFER_AND_EARN_LIST,
  GET_REFER_AND_EARN_LIST_SUCCESS,
  GET_REFER_AND_EARN_LIST_FAILURE,
  ADD_SETTLE_REFER,
  ADD_SETTLE_REFER_SUCCESS,
  ADD_SETTLE_REFER_FAILURE,
  GET_REFER_AND_EARN_FILTER_EMPLOYER_LIST,
  GET_REFER_AND_EARN_FILTER_EMPLOYER_LIST_SUCCESS,
  GET_REFER_AND_EARN_FILTER_EMPLOYER_LIST_FAILURE,
  SEND_SUBUSER_VERIFICATION_MAIL,
  SEND_SUBUSER_VERIFICATION_MAIL_SUCCESS,
  SEND_SUBUSER_VERIFICATION_MAIL_FAILURE,
  GET_EMPLOYER_PERMISSION,
  GET_EMPLOYER_PERMISSION_SUCCESS,
  GET_EMPLOYER_PERMISSION_FAILURE,
  GET_FAQ_LIST,
  GET_FAQ_LIST_SUCCESS,
  GET_FAQ_LIST_FAILURE,
  GET_TAG_LIST,
  GET_TAG_LIST_SUCCESS,
  GET_TAG_LIST_FAILURE,
  ADD_OR_EDIT_FAQ,
  ADD_OR_EDIT_FAQ_SUCCESS,
  ADD_OR_EDIT_FAQ_FAILURE,
  ADD_OR_EDIT_BLOG_REQUEST,
  ADD_OR_EDIT_BLOG_SUCCESS,
  ADD_OR_EDIT_BLOG_FAILURE,
  GET_BLOG_LIST_REQUEST,
  GET_BLOG_LIST_FAILURE,
  GET_BLOG_LIST_SUCCESS,
  DELETE_BLOG_REQUEST,
  DELETE_BLOG_SUCCESS,
  DELETE_BLOG_FAILURE,
  DELETE_FAQ_LIST,
  DELETE_FAQ_LIST_SUCCESS,
  DELETE_FAQ_LIST_FAILURE,
  GET_ACTIVE_EMPLOYER,
  GET_ACTIVE_EMPLOYER_SUCCESS,
  GET_ACTIVE_EMPLOYER_FAILURE,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
  GET_WHITE_LABEL_DETAILS,
  GET_WHITE_LABEL_DETAILS_SUCCESS,
  GET_WHITE_LABEL_DETAILS_FAILURE,
  GET_PRODUCT_PERMISSION,
  GET_PRODUCT_PERMISSION_SUCCESS,
  GET_PRODUCT_PERMISSION_FAILURE,
  GET_PRODUCT_PERMISSION_CREDIT,
  GET_PRODUCT_PERMISSION_CREDIT_SUCCESS,
  GET_PRODUCT_PERMISSION_CREDIT_FAILURE,
  UPDATE_PRODUCT_PERMISSION_CREDIT,
  UPDATE_PRODUCT_PERMISSION_CREDIT_SUCCESS,
  UPDATE_PRODUCT_PERMISSION_CREDIT_FAILURE,
  GET_PRODUCT_PERMISSION_CREDIT_BY_ID,
  GET_PRODUCT_PERMISSION_CREDIT_BY_ID_SUCCESS,
  GET_PRODUCT_PERMISSION_CREDIT_BY_ID_FAILURE,
  UPDATE_PRODUCT_PERMISSION,
  UPDATE_PRODUCT_PERMISSION_SUCCESS,
  UPDATE_PRODUCT_PERMISSION_FAILURE,
  GET_EMPLOYER_SUBUSER_DETAILS,
  GET_EMPLOYER_SUBUSER_DETAILS_SUCCESS,
  GET_EMPLOYER_SUBUSER_DETAILS_FAILURE,
  ADD_PRODUCT_PERMISSION_FAILURE,
  ADD_PRODUCT_PERMISSION_SUCCESS,
  ADD_PRODUCT_PERMISSION,
  NOTIFY_INCSERVE_CLIENT,
  NOTIFY_INCSERVE_CLIENT_SUCCESS,
  NOTIFY_INCSERVE_CLIENT_FAILURE,
  DELETE_FAQS_LIST,
  DELETE_FAQS_LIST_SUCCESS,
  DELETE_FAQS_LIST_FAILURE,
  EDIT_USER_SUPPORT,
  EDIT_USER_SUPPORT_SUCCESS,
  EDIT_USER_SUPPORT_FAILURE,
  GET_ALL_NOTIFICATIONS,
  GET_ALL_NOTIFICATIONS_SUCCESS,
  GET_ALL_NOTIFICATIONS_FAILURE,
  READ_ALL_NOTIFICATIONS,
  READ_ALL_NOTIFICATIONS_SUCCESS,
  READ_ALL_NOTIFICATIONS_FAILURE,
  DELETE_NOTIFICATION,
  DELETE_NOTIFICATION_SUCCESS,
  DELETE_NOTIFICATION_FAILURE,
  CHANGE_EMPLOYER_STATUS,
  CHANGE_EMPLOYER_STATUS_SUCCESS,
  CHANGE_EMPLOYER_STATUS_FAILURE,
  RESTORE_SUBUSER,
  RESTORE_SUBUSER_SUCCESS,
  RESTORE_SUBUSER_FAILURE,
  SUB_ADMIN_PASSWORD_RESET,
  SUB_ADMIN_PASSWORD_RESET_SUCCESS,
  SUB_ADMIN_PASSWORD_RESET_FAILURE,
  INCSERVE_EMPLOYER_ACCOUNT_LOGS,
  INCSERVE_EMPLOYER_ACCOUNT_LOGS_SUCCESS,
  INCSERVE_EMPLOYER_ACCOUNT_LOGS_FAILURE,
  GENERATE_TWO_FACTOR_AUTHENTICATION_URI,
  GENERATE_TWO_FACTOR_AUTHENTICATION_URI_SUCCESS,
  VERIFY_TWO_FACTOR_AUTHENTICATION_URI,
  VERIFY_TWO_FACTOR_AUTHENTICATION_URI_SUCCESS,
  VERIFY_TWO_FACTOR_AUTHENTICATION_URI_FAILURE,
  DISABLE_TWO_FACTOR_AUTHENTICATION_URI,
  DISABLE_TWO_FACTOR_AUTHENTICATION_URI_SUCCESS,
  DISABLE_TWO_FACTOR_AUTHENTICATION_URI_FAILURE,
  GENERATE_TWO_FACTOR_AUTHENTICATION_URI_FAILURE,
  TWO_FACTOR_AUTHENTICATION_STATUS,
  TWO_FACTOR_AUTHENTICATION_STATUS_SUCCESS,
  TWO_FACTOR_AUTHENTICATION_STATUS_FAILURE,
  CHECK_TWO_FACTOR_AUTHENTICATION_STATUS,
  CHECK_TWO_FACTOR_AUTHENTICATION_STATUS_SUCCESS,
  CHECK_TWO_FACTOR_AUTHENTICATION_STATUS_FAILURE,
  GET_MOCK_INTERVIEWS,
  GET_MOCK_INTERVIEWS_SUCCESS,
  GET_MOCK_INTERVIEWS_FAILURE,
} from "../action/types";

const INIT_STATE = {
  loading: false,
  updateSubscriptionLoader: false,
  userData: null,
  adminList: null,
  supportTickets: null,
  employerList: null,
  employerRoleList: null,
  subscriptions: null,
  allEmployerList: null,
  activeEmployerList: null,
  subUserList: null,
  rolesList: null,
  userRoleList: null,
  allSubuserList: null,
  permissionList: null,
  employerPermissionList: null,
  roles: null,
  rolesResources: null,
  resourceActions: null,
  contactInquiry: null,
  announcementList: null,
  companyDetails: null,
  userInformation: null,
  referAndEarnList: null,
  referAndEarnFilterList: null,
  timeStamp: null,
  faqList: null,
  blogList: null,
  tagList: null,
  whitelabelDetails: null,
  productPermission: null,
  productPermissionCredit: null,
  updateProductPermissionList: [],
  getEmployerSubuserDetailsLoading: null,
  employerSubuserDetails: null,
  allNotifications: null,
  notificationLoading: null,
  employerAccountLogs: null,
  generateTwoFactorUri: null,
  twoFactorAuthenticationStatus: null,
  mockInterviews: null,
  creditHistory: null,
  isBlogDeleted: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_EMPLOYER_ROLE_LIST:
      return { ...state, loading: true };
    case GET_EMPLOYER_ROLE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        employerRoleList: action.payload,
      };
    case GET_EMPLOYER_ROLE_LIST_FAILURE:
      return { ...state, loading: false };
    case INCSERVE_EMPLOYER_ACCOUNT_LOGS:
      return { ...state, loading: true };
    case INCSERVE_EMPLOYER_ACCOUNT_LOGS_SUCCESS:
      return {
        ...state,
        loading: false,
        employerAccountLogs: action.payload,
      };
    case INCSERVE_EMPLOYER_ACCOUNT_LOGS_FAILURE:
      return { ...state, loading: false };
    case LOGOUT_USER:
      return { ...state, loading: true };
    case LOGOUT_USER_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case LOGOUT_USER_FAILURE:
      return { ...state, loading: false };
    case GET_WHITE_LABEL_DETAILS:
      return { ...state, loading: true };
    case GET_WHITE_LABEL_DETAILS_SUCCESS:
      return { ...state, loading: false, whitelabelDetails: action.payload };
    case GET_WHITE_LABEL_DETAILS_FAILURE:
      return { ...state, loading: false };
    case GET_ACTIVE_EMPLOYER:
      return { ...state, loading: true };
    case GET_ACTIVE_EMPLOYER_SUCCESS:
      return {
        ...state,
        loading: false,
        activeEmployerList: action.payload,
      };
    case GET_ACTIVE_EMPLOYER_FAILURE:
      return { ...state, loading: false };

    case GET_USER_LIST:
      return { ...state, loading: true };
    case GET_USER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        adminList: action.payload,
      };
    case GET_USER_LIST_FAILURE:
      return { ...state, loading: false };
    case EDIT_STATUS_CONTACT_INQUIRY:
      return { ...state, loading: true };
    case EDIT_STATUS_CONTACT_INQUIRY_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case EDIT_STATUS_CONTACT_INQUIRY_FAILURE:
      return { ...state, loading: false };
    case CHANGE_EMPLOYER_STATUS:
      return { ...state, loading: true };
    case CHANGE_EMPLOYER_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case CHANGE_EMPLOYER_STATUS_FAILURE:
      return { ...state, loading: false };
    case EDIT_USER_CONTACT_INQUIRY:
      return { ...state, loading: true };
    case EDIT_USER_CONTACT_INQUIRY_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case EDIT_USER_CONTACT_INQUIRY_FAILURE:
      return { ...state, loading: false };
    case EDIT_USER_SUPPORT:
      return { ...state, loading: true };
    case EDIT_USER_SUPPORT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case EDIT_USER_SUPPORT_FAILURE:
      return { ...state, loading: false };

    case LOGIN_USER:
      return { ...state, loading: true };
    case LOGIN_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        userData: action.payload,
      };
    case LOGIN_USER_FAILURE:
      return { ...state, loading: false };

    case LOGIN_WITH_GOOGLE:
      return { ...state, loading: true };
    case LOGIN_WITH_GOOGLE_SUCCESS:
      return {
        ...state,
        loading: false,
        userData: action.payload,
      };
    case LOGIN_WITH_GOOGLE_FAILURE:
      return { ...state, loading: false };

    case SIGNUP_USER:
      return { ...state, loading: true };
    case SIGNUP_USER_SUCCESS:
      return { ...state, loading: false };
    case SIGNUP_USER_FAILURE:
      return { ...state, loading: false };
    case MANUALLY_VERIFY_EMAIL:
      return { ...state, loading: true };
    case MANUALLY_VERIFY_EMAIL_SUCCESS:
      return { ...state, loading: false };
    case MANUALLY_VERIFY_EMAIL_FAILURE:
      return { ...state, loading: false };
    // FORGET PASSWORD
    case FORGOT_PASSWORD:
      return { ...state, loading: true };
    case FORGOT_PASSWORD_SUCCESS:
      return { ...state, loading: false };
    case FORGOT_PASSWORD_FAILURE:
      return { ...state, loading: false };
    case FORGOT_PASSWORD_TOKEN_VERIFY:
      return { ...state, loading: true };
    case FORGOT_PASSWORD_TOKEN_VERIFY_SUCCESS:
      return { ...state, loading: false };
    case FORGOT_PASSWORD_TOKEN_VERIFY_FAILURE:
      return { ...state, loading: false };
    case RESET_PASSWORD:
      return { ...state, loading: true };
    case RESET_PASSWORD_SUCCESS:
      return { ...state, loading: false };
    case RESET_PASSWORD_FAILURE:
      return { ...state, loading: false };
    // EMPLOYER REDUCER
    case GET_EMPLOYER_LIST:
      return { ...state, loading: action.payload.search ? false : true };
    case GET_EMPLOYER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        timeStamp:
          state.timeStamp > action.payload.timeStamp
            ? state.timeStamp
            : action.payload.timeStamp,
        employerList:
          state.timeStamp > action.payload.timeStamp
            ? state.employerList
            : action.payload,
      };
    case GET_EMPLOYER_LIST_FAILURE:
      return { ...state, loading: false };
    case GET_ALL_EMPLOYERS:
      return { ...state, loading: true };
    case GET_ALL_EMPLOYERS_SUCCESS:
      return {
        ...state,
        loading: false,
        timeStamp:
          state.timeStamp > action.payload.timeStamp
            ? state.timeStamp
            : action.payload.timeStamp,
        allEmployerList:
          state.timeStamp > action.payload.timeStamp
            ? state.allEmployerList
            : action.payload,
      };
    case GET_ALL_EMPLOYERS_FAILURE:
      return { ...state, loading: false };
    case SEND_EMAIL_VERIFICATION:
      return { ...state, loading: true };
    case SEND_EMAIL_VERIFICATION_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case SEND_EMAIL_VERIFICATION_FAILURE:
      return { ...state, loading: false };
    case DELETE_EMPLOYER_PROFILE:
      return { ...state, loading: true };
    case DELETE_EMPLOYER_PROFILE_SUCCESS:
      return { ...state, loading: false };
    case DELETE_EMPLOYER_PROFILE_FAILURE:
      return { ...state, loading: false };
    case DELETE_EMPLOYER: {
      return { ...state, loading: true };
    }
    case DELETE_EMPLOYER_SUCCESS: {
      return { ...state, loading: false };
    }
    case DELETE_EMPLOYER_FAILURE: {
      return { ...state, loading: false };
    }
    case RESTORE_PROFILE: {
      return { ...state, loading: true };
    }
    case RESTORE_PROFILE_SUCCESS: {
      return { ...state, loading: false };
    }
    case RESTORE_PROFILE_FAILURE: {
      return { ...state, loading: false };
    }
    case EMPLOYER_SUBSCRIPTION: {
      return { ...state, loading: true };
    }
    case EMPLOYER_SUBSCRIPTION_SUCCESS: {
      return { ...state, loading: false, subscriptions: action.payload };
    }
    case EMPLOYER_SUBSCRIPTION_FAILURE: {
      return { ...state, loading: false };
    }
    case GET_CREDIT_HISTORY: {
      return { ...state, loading: true };
    }
    case GET_CREDIT_HISTORY_SUCCESS: {
      return { ...state, loading: false, creditHistory: action.payload };
    }
    case GET_CREDIT_HISTORY_FAILURE: {
      return { ...state, loading: false };
    }
    case UPDATE_CREDIT_HISTORY: {
      return { ...state, loading: true };
    }
    case UPDATE_CREDIT_HISTORY_SUCCESS: {
      return { ...state, loading: false };
    }
    case UPDATE_CREDIT_HISTORY_FAILURE: {
      return { ...state, loading: false };
    }
    case UPDATE_SUBSCRIPTION: {
      return { ...state, loading: true };
    }
    case UPDATE_SUBSCRIPTION_SUCCESS: {
      return { ...state, loading: false };
    }
    case UPDATE_SUBSCRIPTION_FAILURE: {
      return { ...state, loading: false };
    }
    // ROLES AND SUBUSER
    case ADD_OR_EDIT_SUB_USER:
      return { ...state, loading: true };
    case ADD_OR_EDIT_SUB_USER_SUCCESS:
      return { ...state, loading: false };
    case ADD_OR_EDIT_SUB_USER_FAILURE:
      return { ...state, loading: false };
    case ADD_OR_EDIT_EMPLOYER_SUB_USER:
      return { ...state, loading: true };
    case ADD_OR_EDIT_EMPLOYER_SUB_USER_SUCCESS:
      return { ...state, loading: false };
    case ADD_OR_EDIT_EMPLOYER_SUB_USER_FAILURE:
      return { ...state, loading: false };
    case GET_SUB_USER:
      return { ...state, loading: true };
    case GET_SUB_USER_SUCCESS:
      return { ...state, loading: false, subUserList: action.payload };
    case GET_SUB_USER_FAILURE:
      return { ...state, loading: false };
    case RESTORE_SUBUSER:
      return { ...state, loading: true };
    case RESTORE_SUBUSER_SUCCESS:
      return { ...state, loading: false };
    case RESTORE_SUBUSER_FAILURE:
      return { ...state, loading: false };
    case DELETE_SUB_USER:
      return { ...state, loading: true };
    case DELETE_SUB_USER_SUCCESS:
      return { ...state, loading: false };
    case DELETE_SUB_USER_FAILURE:
      return { ...state, loading: false };
    case DELETE_EMPLOYER_SUB_USER:
      return { ...state, loading: true };
    case DELETE_EMPLOYER_SUB_USER_SUCCESS:
      return { ...state, loading: false };
    case DELETE_EMPLOYER_SUB_USER_FAILURE:
      return { ...state, loading: false };
    case RESEND_LINK:
      return { ...state, loading: true };
    case RESEND_LINK_SUCCESS:
      return { ...state, loading: false };
    case RESEND_LINK_FAILURE:
      return { ...state, loading: false };
    case ACTIVE_INACTIVE_SUBUSER:
      return { ...state, loading: true };
    case ACTIVE_INACTIVE_SUBUSER_SUCCESS:
      return { ...state, loading: false };
    case ACTIVE_INACTIVE_SUBUSER_FAILURE:
      return { ...state, loading: false };
    case CHANGE_BLOG_STATUS:
      return { ...state, loading: true };
    case CHANGE_BLOG_STATUS_SUCCESS:
      return { ...state, loading: true };
    case CHANGE_BLOG_STATUS_FAILURE:
      return { ...state, loading: true };
    case ADD_OR_EDIT_ROLE:
      return { ...state, loading: true };
    case ADD_OR_EDIT_ROLE_SUCCESS:
      return { ...state, loading: false };
    case ADD_OR_EDIT_ROLE_FAILURE:
      return { ...state, loading: false };
    case GET_ROLES:
      return { ...state, loading: true };
    case GET_ROLES_SUCCESS: {
      return {
        ...state,
        loading: false,
        timeStamp:
          state.timeStamp > action.payload.timeStamp
            ? state.timeStamp
            : action.payload.timeStamp,
        rolesList:
          state.timeStamp > action.payload.timeStamp
            ? state.rolesList
            : action.payload,
      };
    }
    case GET_ROLES_FAILURE:
      return { ...state, loading: false };
    case DELETE_ROLES:
      return { ...state, loading: true };
    case DELETE_ROLES_SUCCESS:
      return { ...state, loading: false };
    case DELETE_ROLES_FAILURE:
      return { ...state, loading: false };
    case ACTIVE_INACTIVE_ROLE:
      return { ...state, loading: true };
    case ACTIVE_INACTIVE_ROLE_SUCCESS:
      return { ...state, loading: false };
    case ACTIVE_INACTIVE_ROLE_FAILURE:
      return { ...state, loading: false };
    case GET_PERMISSION:
      return { ...state, loading: true };
    case GET_PERMISSION_SUCCESS:
      return { ...state, loading: false, permissionList: action.payload };
    case GET_PERMISSION_FAILURE:
      return { ...state, loading: false };
    case GET_EMPLOYER_PERMISSION:
      return { ...state, loading: true };
    case GET_EMPLOYER_PERMISSION_SUCCESS:
      return {
        ...state,
        loading: false,
        employerPermissionList: action.payload,
      };
    case GET_EMPLOYER_PERMISSION_FAILURE:
      return { ...state, loading: false };
    case ADD_ROLES:
      return { ...state, loading: true };
    case ADD_ROLES_SUCCESS:
      return { ...state, loading: false };
    case ADD_ROLES_FAILURE:
      return { ...state, loading: false };
    case GET_ROLES_RESOURCES:
      return { ...state, loading: true };
    case GET_ROLES_RESOURCES_SUCCESS:
      return { ...state, loading: false, rolesResources: action.payload };
    case GET_ROLES_RESOURCE_FAILURE:
      return { ...state, loading: false };
    case GET_RESOURCE_ACTIONS:
      return { ...state };
    case GET_RESOURCE_ACTIONS_SUCCESS:
      return { ...state, resourceActions: action.payload };
    case GET_RESOURCE_ACTIONS_FAILURE:
      return { ...state };
    case GET_ALL_SUB_USER:
      return { ...state, loading: true };
    case GET_ALL_SUB_USER_SUCCESS:
      return { ...state, loading: false, allSubUserList: action.payload };
    case GET_ALL_SUB_USER_FAILURE:
      return { ...state, loading: false };
    //USERS
    case GET_CONTACT_INQUIRY:
      return { ...state, loading: true };
    case GET_CONTACT_INQUIRY_SUCCESS:
      return {
        ...state,
        loading: false,
        timeStamp:
          state.timeStamp > action.payload.timeStamp
            ? state.timeStamp
            : action.payload.timeStamp,
        contactInquiry:
          state.timeStamp > action.payload.timeStamp
            ? state.contactInquiry
            : action.payload,
      };
    case GET_CONTACT_INQUIRY_FAILURE:
      return { ...state, loading: false };
    case GET_ANNOUNCEMENT_LIST:
      return { ...state, loading: true };
    case GET_ANNOUNCEMENT_LIST_SUCCESS:
      return { ...state, loading: false, announcementList: action.payload };
    case GET_ANNOUNCEMENT_LIST_FAILURE:
      return { ...state, loading: true };
    case DELETE_ANNOUNCEMENT:
      return { ...state, loading: true };
    case DELETE_ANNOUNCEMENT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case DELETE_ANNOUNCEMENT_FAILURE:
      return { ...state, loading: false };
    case GET_USER_INFORMATION:
      return { ...state, loading: true };
    case GET_USER_INFORMATION_SUCCESS:
      return {
        ...state,
        loading: false,
        userInformation: action.payload,
      };
    case GET_USER_INFORMATION_FAILURE:
      return { ...state, loading: false };
    case UPDATE_USER_INFORMATION:
      return { ...state, loading: true };
    case UPDATE_USER_INFORMATION_SUCCESS:
      return { ...state, loading: false };
    case UPDATE_USER_INFORMATION_FAILURE:
      return { ...state, loading: false };
    case UPDATE_COMPANY_DETAILS:
      return { ...state, loading: true };
    case UPDATE_COMPANY_DETAILS_SUCCESS:
      return { ...state, loading: false };
    case UPDATE_COMPANY_DETAILS_FAILURE:
      return { ...state, loading: false };
    case GET_COMPANY_DETAILS:
      return { ...state, loading: true };
    case GET_COMPANY_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        companyDetails: action.payload,
      };
    case GET_COMPANY_DETAILS_FAILURE:
      return { ...state, loading: false };
    case GET_SUPPORT_TICKET:
      return { ...state, loading: true };
    case GET_SUPPORT_TICKET_SUCCESS:
      return { ...state, loading: false, supportTickets: action.payload };
    case GET_SUPPORT_TICKET_FAILURE:
      return { ...state, loading: false };
    case UPDATE_SUPPORT_TICKET_STATUS:
      return { ...state, loading: true };
    case UPDATE_SUPPORT_TICKET_STATUS_SUCCESS:
      return { ...state, loading: false };
    case UPDATE_SUPPORT_TICKET_STATUS_FAILURE:
      return { ...state, loading: false };
    case SEND_ANNOUNCEMENT_ITEM:
      return { ...state, loading: true };
    case SEND_ANNOUNCEMENT_ITEM_SUCCESS:
      return { ...state, loading: false };
    case SEND_ANNOUNCEMENT_ITEM_FAILURE:
      return { ...state, loading: false };
    case GET_REFER_AND_EARN_LIST:
      return { ...state, loading: true };
    case GET_REFER_AND_EARN_LIST_SUCCESS:
      return { ...state, loading: false, referAndEarnList: action.payload };
    case GET_REFER_AND_EARN_LIST_FAILURE:
      return { ...state, loading: false };
    case GET_REFER_AND_EARN_FILTER_EMPLOYER_LIST:
      return { ...state, loading: true };
    case GET_REFER_AND_EARN_FILTER_EMPLOYER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        timeStamp:
          state.timeStamp > action.payload.timeStamp
            ? state.timeStamp
            : action.payload.timeStamp,
        referAndEarnfilterEmployerList:
          state.timeStamp > action.payload.timeStamp
            ? state.referAndEarnfilterEmployerList
            : action.payload,
      };
    case GET_REFER_AND_EARN_FILTER_EMPLOYER_LIST_FAILURE:
      return { ...state, loading: false };
    case ADD_SETTLE_REFER:
      return { ...state, loading: true };
    case ADD_SETTLE_REFER_SUCCESS:
      return { ...state, loading: false };
    case ADD_SETTLE_REFER_FAILURE:
      return { ...state, loading: false };
    case SEND_SUBUSER_VERIFICATION_MAIL:
      return { ...state };
    case SEND_SUBUSER_VERIFICATION_MAIL_SUCCESS:
      return {
        ...state,
      };
    case SEND_SUBUSER_VERIFICATION_MAIL_FAILURE:
      return { ...state };
    case GET_FAQ_LIST:
      return { ...state, loading: true };
    case GET_FAQ_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        timeStamp:
          state.timeStamp > action.payload.timeStamp
            ? state.timeStamp
            : action.payload.timeStamp,
        faqList:
          state.timeStamp > action.payload.timeStamp
            ? state.employerList
            : action.payload,
      };
    case GET_FAQ_LIST_FAILURE:
      return { ...state, loading: false };
    case GET_TAG_LIST:
      return { ...state, loading: true };
    case GET_TAG_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        timeStamp:
          state.timeStamp > action.payload.timeStamp
            ? state.timeStamp
            : action.payload.timeStamp,
        tagList:
          state.timeStamp > action.payload.timeStamp
            ? state.employerList
            : action.payload,
      };
    case GET_TAG_LIST_FAILURE:
      return { ...state, loading: false };
    case ADD_OR_EDIT_BLOG_REQUEST:
      return { ...state, loading: true };
    case ADD_OR_EDIT_BLOG_SUCCESS:
      return { ...state, loading: false };
    case ADD_OR_EDIT_BLOG_FAILURE:
      return { ...state, loading: false };
    case GET_BLOG_LIST_REQUEST:
      return { ...state, loading: true };
    case GET_BLOG_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        blogList: action.payload // TODO: set the blog list
      };
    case GET_BLOG_LIST_FAILURE:
      return { ...state, loading: false };
    case DELETE_BLOG_REQUEST:
      return { ...state, loading: true, isBlogDeleted: "requested" };
    case DELETE_BLOG_SUCCESS:
      return { ...state, loading: false, isBlogDeleted: "success" };
    case DELETE_BLOG_FAILURE:
      return { ...state, loading: false, isBlogDeleted: "failure" };
    case ADD_OR_EDIT_FAQ:
      return { ...state, loading: true };
    case ADD_OR_EDIT_FAQ_SUCCESS:
      return { ...state, loading: false };
    case ADD_OR_EDIT_FAQ_FAILURE:
      return { ...state, loading: false };
    case DELETE_FAQ_LIST:
      return { ...state, loading: true };
    case DELETE_FAQ_LIST_SUCCESS:
      return { ...state, loading: false };
    case DELETE_FAQ_LIST_FAILURE:
      return { ...state, loading: false };
    case DELETE_FAQS_LIST:
      return { ...state, loading: true };
    case DELETE_FAQS_LIST_SUCCESS:
      return { ...state, loading: false };
    case DELETE_FAQS_LIST_FAILURE:
      return { ...state, loading: false };
    case CHANGE_PASSWORD:
      return { ...state, loading: true };
    case CHANGE_PASSWORD_SUCCESS:
      return { ...state, loading: false };
    case CHANGE_PASSWORD_FAILURE:
      return { ...state, loading: false };
    case GET_PRODUCT_PERMISSION:
      return { ...state, loading: true, productPermission: null };
    case GET_PRODUCT_PERMISSION_SUCCESS:
      return { ...state, loading: false, productPermission: action.payload };
    case GET_PRODUCT_PERMISSION_FAILURE:
      return { ...state, loading: false };

    case GET_PRODUCT_PERMISSION_CREDIT:
      return { ...state, loading: true, productPermissionCredit: null };
    case GET_PRODUCT_PERMISSION_CREDIT_SUCCESS:
      return {
        ...state,
        loading: false,
        productPermissionCredit: action.payload,
      };
    case GET_PRODUCT_PERMISSION_CREDIT_FAILURE:
      return { ...state, loading: false };

    case GET_PRODUCT_PERMISSION_CREDIT_BY_ID:
      return { ...state, loading: true };
    case GET_PRODUCT_PERMISSION_CREDIT_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        productPermissionCredit: action.payload,
      };
    case GET_PRODUCT_PERMISSION_CREDIT_BY_ID_FAILURE:
      return { ...state, loading: false };

    case UPDATE_PRODUCT_PERMISSION_CREDIT:
      return { ...state, loading: true };
    case UPDATE_PRODUCT_PERMISSION_CREDIT_SUCCESS:
      return {
        ...state,
        loading: false,
        updateProductPermissionList: action.payload,
      };
    case UPDATE_PRODUCT_PERMISSION_CREDIT_FAILURE:
      return { ...state, loading: false };

    case UPDATE_PRODUCT_PERMISSION:
      return { ...state, loading: true };
    case UPDATE_PRODUCT_PERMISSION_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_PRODUCT_PERMISSION_FAILURE:
      return { ...state, loading: false };

    case ADD_PRODUCT_PERMISSION:
      return { ...state, updateSubscriptionLoader: true };
    case ADD_PRODUCT_PERMISSION_SUCCESS:
      return {
        ...state,
        updateSubscriptionLoader: false,
      };
    case ADD_PRODUCT_PERMISSION_FAILURE:
      return { ...state, updateSubscriptionLoader: false };

    case NOTIFY_INCSERVE_CLIENT:
      return { ...state, loading: true };
    case NOTIFY_INCSERVE_CLIENT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case NOTIFY_INCSERVE_CLIENT_FAILURE:
      return { ...state, loading: false };

    case GET_EMPLOYER_SUBUSER_DETAILS:
      return { ...state, getEmployerSubuserDetailsLoading: true };
    case GET_EMPLOYER_SUBUSER_DETAILS_SUCCESS:
      return {
        ...state,
        employerSubuserDetails: action.payload,
        getEmployerSubuserDetailsLoading: false,
      };
    case GET_EMPLOYER_SUBUSER_DETAILS_FAILURE:
      return { ...state, getEmployerSubuserDetailsLoading: false };

    case GET_ALL_NOTIFICATIONS:
      return { ...state, notificationLoading: true };
    case GET_ALL_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        allNotifications: action.payload,
        notificationLoading: false,
      };
    case GET_ALL_NOTIFICATIONS_FAILURE:
      return { ...state, notificationLoading: false };

    case READ_ALL_NOTIFICATIONS:
      return { ...state, notificationLoading: true };
    case READ_ALL_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notificationLoading: false,
      };
    case READ_ALL_NOTIFICATIONS_FAILURE:
      return { ...state, notificationLoading: false };

    case DELETE_NOTIFICATION:
      return { ...state, notificationLoading: true };
    case DELETE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notificationLoading: false,
      };
    case DELETE_NOTIFICATION_FAILURE:
      return { ...state, notificationLoading: false };

    case SUB_ADMIN_PASSWORD_RESET:
      return { ...state, loading: true };
    case SUB_ADMIN_PASSWORD_RESET_SUCCESS:
      return { ...state, loading: false };
    case SUB_ADMIN_PASSWORD_RESET_FAILURE:
      return { ...state, loading: false };

    case GENERATE_TWO_FACTOR_AUTHENTICATION_URI:
      return { ...state, generateTwoFactorUriLoading: true };
    case GENERATE_TWO_FACTOR_AUTHENTICATION_URI_SUCCESS:
      return {
        ...state,
        generateTwoFactorUri: action.payload,
        generateTwoFactorUriLoading: false,
      };
    case GENERATE_TWO_FACTOR_AUTHENTICATION_URI_FAILURE:
      return { ...state, generateTwoFactorUriLoading: false };

    case VERIFY_TWO_FACTOR_AUTHENTICATION_URI:
      return { ...state, loading: true };
    case VERIFY_TWO_FACTOR_AUTHENTICATION_URI_SUCCESS:
      return { ...state, loading: false };
    case VERIFY_TWO_FACTOR_AUTHENTICATION_URI_FAILURE:
      return { ...state, loading: false };

    case DISABLE_TWO_FACTOR_AUTHENTICATION_URI:
      return { ...state, loading: true };
    case DISABLE_TWO_FACTOR_AUTHENTICATION_URI_SUCCESS:
      return { ...state, loading: false };
    case DISABLE_TWO_FACTOR_AUTHENTICATION_URI_FAILURE:
      return { ...state, loading: false };

    case TWO_FACTOR_AUTHENTICATION_STATUS:
      return { ...state, loading: true };
    case TWO_FACTOR_AUTHENTICATION_STATUS_SUCCESS:
      return {
        ...state,
        twoFactorAuthenticationStatus: action.payload,
        loading: false,
      };
    case TWO_FACTOR_AUTHENTICATION_STATUS_FAILURE:
      return { ...state, loading: false };

    case CHECK_TWO_FACTOR_AUTHENTICATION_STATUS:
      return { ...state, loading: true };
    case CHECK_TWO_FACTOR_AUTHENTICATION_STATUS_SUCCESS:
      return { ...state, loading: false };
    case CHECK_TWO_FACTOR_AUTHENTICATION_STATUS_FAILURE:
      return { ...state, loading: false };
    case GET_MOCK_INTERVIEWS:
      return { ...state, loading: true };
    case GET_MOCK_INTERVIEWS_SUCCESS:
      return { ...state, loading: false, mockInterviews: action.payload };
    case GET_MOCK_INTERVIEWS_FAILURE:
      return { ...state, loading: false };
    default:
      return state;
  }
};
